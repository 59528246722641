import axios from 'axios';
import { BaseURL } from './config';

const api = axios.create({
  baseURL: BaseURL
});

export default {
  siteIcon: 'ion-flash',
  footerText: `Melian @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  apiUrl: 'http://yoursite.com/api/',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};

export { 
  api,
};

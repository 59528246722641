import actions from './actions';

const initState = { 
  idToken: null,
  userName: null,
  twoStepVerification: null,
  twoStepVerificationIsActive: null,
  capabilities: [],
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        userName: action.userName,
        twoStepVerificationIsActive: action.twoStepVerificationIsActive,
        capabilities: action.capabilities,
      };
    case actions.TWO_STEP_VERIFICATION_SUCCESS:
      return { 
        ...state,
        twoStepVerification: action.twoStepVerification,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}

const BaseURL = 'https://app-end.linkspace.la/api';

const titles = {
  siteName: 'LinkSpace',
}

const logos = {
  logoTopbar: 'logo_oficial_link_space.png',
  logoLogin: 'logo_oficial_link_space.png',
  backgroundLogin: 'background.jpg',
}
export { 
  BaseURL,
  titles,
  logos
}
